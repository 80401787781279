<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col md="4">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">GUIDES UTILISATEUR</h1>
        </b-col>
      </b-row>
    </div>

    <b-overlay :show="showLoading" no-wrap />
    <vue-good-table
      class="custom-table-bottom"
      v-if="can('List modeles')"
      :columns="utilisateurColumns"
      :rows="rowsUtilisateur"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'type_activite'">
          {{ typeActiviteOptions.find(item => item.value === props.row.type_activite).text }}
        </span>
        <span v-else-if="props.column.field === 'actions'">
          <span>
             <feather-icon icon="DownloadCloudIcon" size="20" class="mr-50" />
          </span>
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination, VBToggle, BRow, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay, BFormSelect } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VBToggle,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ToastificationContentVue,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      typeActiviteOptions: [
        { value: 'MESSAGE', text: 'Message' },
        { value: 'TACHE', text: 'Tâche' },
        { value: 'RENDEZVOUS', text: 'Rendez-vous' },
        { value: 'ECHEANCE', text: 'Échéance' },
        { value: 'RAPPEL', text: 'Rappel' },
        { value: 'NOTES', text: 'Notes' }
      ],
      showLoading: false,
      pageLength: 3,
      dir: false,
      renderComponent: true,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      modelChoosed: { id: null, type_activite: null, modele: null, objet: null, corps_mail: null, courtier_id: null },
      modalAction: 'Nouveau',
      rowsUtilisateur: [{
          guide:'Importer un client',
          objet:'Ce document a pour but d\'expliquer l\'importation de votre base client via l\'outils de l\'application'
      }],
      utilisateurColumns: [
        { label: 'Nom du guide', field: 'guide', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Description', field: 'objet', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Actions', field: 'actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' }
      ]
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  },
  created() {
  },
  methods: {
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
div.custom-table-bottom >div >.vgt-responsive{
    min-height: 0 !important;
}
</style>