var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mx-auto mb-2"},[_c('b-row',{staticClass:"justify-content-between align-items-center"},[_c('b-col',{attrs:{"md":"4"}},[_c('h1',{staticClass:"text-primary font-weight-bolder text-uppercase ml-1 my-0"},[_vm._v("GUIDES UTILISATEUR")])])],1)],1),_c('b-overlay',{attrs:{"show":_vm.showLoading,"no-wrap":""}}),(_vm.can('List modeles'))?_c('vue-good-table',{staticClass:"custom-table-bottom",attrs:{"columns":_vm.utilisateurColumns,"rows":_vm.rowsUtilisateur,"rtl":_vm.direction,"style-class":"vgt-table condensed","pagination-options":{
      enabled: true,
      mode: 'records',
      perPage: _vm.pageLength,
      rowsPerPageLabel: '',
      dropdownAllowAll: false,
      perPageDropdownEnabled: false,
      nextLabel: 'Suivant',
      prevLabel: 'Précédent',
      ofLabel: 'de',
      infoFn: function (params) { return ""; }
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'type_activite')?_c('span',[_vm._v(" "+_vm._s(_vm.typeActiviteOptions.find(function (item) { return item.value === props.row.type_activite; }).text)+" ")]):(props.column.field === 'actions')?_c('span',[_c('span',[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadCloudIcon","size":"20"}})],1)]):_vm._e()]}},{key:"pagination-bottom",fn:function(props){return [_c('b-row',{staticClass:"mt-2 align-items-center"},[_c('b-col',{attrs:{"md":"6","lg":"5","xxl":"3"}},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Affichage 1 à ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['3','5','10']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" entrées ")])],1)])],1),_c('b-row',{staticClass:"mt-2 align-items-center"},[_c('b-col',[_c('b-pagination',{attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value) { return props.pageChanged({ currentPage: value }); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)],1)]}}],null,false,464770497)},[_c('div',{staticClass:"text-center align-middle",attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('span',[_vm._v("Aucune donnée disponible dans le tableau")])])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }