<template>
  <div>
    <!-- Title page -->
    <div class="mx-auto mb-2">
      <b-row class="justify-content-between align-items-center">
        <b-col sm="8">
          <h1 class="text-primary font-weight-bolder text-uppercase ml-1 my-0">Guides de conformité</h1>
        </b-col>
        <!--  -->
        <b-col sm="4" >
          <div class="custom-search d-flex justify-content-end">
          <b-button   v-b-toggle.sidebar-1 
            variant="primary"
            size="md"  
            v-if="showbutton"
            >
            <feather-icon icon="PlusIcon"/>
            <span class="align-middle">  Ajouter guide</span>
          </b-button>
          </div>
        </b-col>      
      <b-sidebar  id="sidebar-1" ref="sideBarProjetExterne"
        bg-variant="white"
        sidebar-class="sidebar-lg"
        shadow
        backdrop
        no-header
        right>
        <template>
          <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Nouveau Guide</h5>
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
          </div>
          <validation-observer ref="guidedocument">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-overlay :show="showLoading" no-wrap />
            <b-form-group label="Nom de guide*" label-for="nomguide" >
              <validation-provider #default="{ errors }" name="Nom de guide" rules="required">
              <b-form-input v-model="composeData.nomguide" id="nomguide" placeholder="Nom de guide" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Description*"  label-for="description">
              <validation-provider #default="{ errors }" name="description" rules="required">
            <b-form-textarea v-model="composeData.description" id="description" placeholder="Écrire la description" />
            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group label="Nom de document *" label-for="action">
              <validation-provider #default="{ errors }" name="document" rules="required">
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button id="action" variant="primary" class="btn-icon"  v-b-tooltip.hover="'Parcourir le fichier'" @click="openToSelectFile" >
                      <b-form-file type="file" id="file" class="d-none" v-model="composeData.file"/>
                      <feather-icon icon="UploadCloudIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input disabled placeholder="Choisissez un document" :value="composeData.file ? composeData.file.name : ''"  />
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>             
            </b-form-group>
            <b-button
            @click="handleSubmit"
              variant="primary"
              size="md"  >
              <span class="align-middle">  Ajouter guide</span>
            </b-button>
          </b-form>
        </validation-observer>
        </template>
      </b-sidebar>
      </b-row>
    </div>
    <b-overlay :show="showLoading" no-wrap />
    <vue-good-table
      class="custom-table-bottom"
      :columns="metierColumns"
      :rows="rowsMetier"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'document_id'  ">
          <span class="cursor-pointer">
             <feather-icon @click="getBase64Document(props.row.document_id,props.row.nom)" icon="DownloadCloudIcon" size="20" class="mr-50" />
          </span>
          <span v-if="showbutton" class="cursor-pointer">
             <feather-icon @click="deleteGuide(props.row.document_id)" v-b-toggle.sidebar-2 icon="TrashIcon" size="20" class="mr-50" />
          </span>
          
        </span>
  

      </template>
 
 


      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div slot="emptystate" class="text-center align-middle">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { VBTooltip, BFormInvalidFeedback, BInputGroupPrepend, BFormFile, BFormInput, BFormGroup, BPagination, BForm, VBToggle, BRow, BCol, BDropdown, BDropdownItem, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay, BFormSelect, BSidebar, BFormTextarea, BInputGroup, BTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'
import { VueGoodTable } from 'vue-good-table'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BFormInvalidFeedback,
    VBToggle,
    BInputGroupPrepend,
    BFormFile,
    BFormInput,
    BFormGroup,
    BForm,
    BFormTextarea,
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BSidebar,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    ToastificationContentVue
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    showbutton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      showLoading: false,
      pageLength: 3,
      dir: false,
      renderComponent: true,
      modalAction: 'Nouveau',
      rowsMetier: [],
      metierColumns: [
        { label: 'Nom du guide', field: 'nom', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Description', field: 'description', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },
        { label: 'Actions', field: 'document_id', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking', tdClass: 'text-center m-0 p-2 align-middle' },

      ],
      composeData: {
        file: null,
        description: null,
        nomguide: null,
        id: null,
      },
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    }
  },
  created() {
    console.log(this.$route.name)
    if (this.$route.name == 'guide') {
      this.$router.push({
        name: 'conformite-guide',
        
      })
    }
    
    this.fetchGuide()
  },
  methods: {
    forceRerender() {
      this.renderComponent = false
      this.$nextTick(() => {
        this.renderComponent = true
      })
    },
    hide() {
      this.$refs.sideBarProjetExterne.hide()
      this.clearData()

    },
    openToSelectFile: () => {
      document.getElementById('file').click()

    },
    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    getBase64Document(id, name) {
      this.$http
        .get(`/document/generate_base64_for_document/${id}`)
        .then(res => {
          if (res.data.success) {
              this.downloadDocument(res.data.data.base64, name)
          } else {
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
      clearData() {
      this.composeData.idguide = null
      this.composeData.nomguide = null
      this.composeData.description = ''
      this.composeData.file = null
      this.$refs.guidedocument.reset()
    },
           // this.rowsConformiteKeeped = res.data.data
              // this.rowsConformite = res.data.data
              // this.rowsConformite = this.rowsConformiteKeeped.filter(item => item.etat === this.searchTerm)
    fetchGuide() {
      this.showLoading = true
      this.rowsMetier = []
      this.$http
          .get(`/ecaglobal/fetchGuide`)
          .then(res => {
            if (res.data.success) {
              this.rowsMetier = res.data.data
              this.showLoading = false
            } else {
              this.showLoading = false
              this.messageToast('Nous n\'avons pas pu trouver la ressource que vous avez demandée.', 'Erreur', 'warning')
            }
          })
          .catch(err => {
            this.showLoading = false
            this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
            console.error(err)
          })
    },
    handleSubmit(){
      const formData = new FormData()
          this.$refs.guidedocument.validate()
          .then(success => {
            if (success) {
              this.showLoading = true
              formData.append('nomguide', this.composeData.nomguide)
              formData.append('description', this.composeData.description)
              formData.append('file', this.composeData.file)
              formData.append('id', this.composeData.idguide)
            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            this.$http
                  .post('/ecaglobal/addGuideDoc', formData, config)
                  .then(res => {
                    if (res.data.success){
                      this.clearData()
                      this.hide()
                      this.fetchGuide()
                      this.showLoading = false
                      this.messageToast('Le guide a été Bien ajouter', 'Succès', 'success')
                    } 
                    else {                
                      this.showLoading = false
                      this.messageToast('Erreur lors de creation de nouveau guide.', 'Erreur', 'error')
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  })
            }
            })
            },
      deleteGuide(id){
        this.$swal({
        title: 'Etes-vous sûr de vouloir supprimer ce guide ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(result => {
            if (result.value) {
        console.log(id)
        this.composeData.id=id
        const formData = new FormData()
   
              this.showLoading = true

              formData.append('id', this.composeData.id)
            const config = { headers: { 'Content-Type': 'multipart/form-data' } }
            this.$http
                  .post('/ecaglobal/deleteGuide', formData, config)
                  .then(res => {
                    if (res.data.success){
                      this.fetchGuide()
                      this.showLoading = false
                      this.messageToast('Le guide a été Bien supprimer', 'Succès', 'success')

                    } 
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                  })
                }
          }
        )

      }
    },
  }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
div.custom-table-bottom >div >.vgt-responsive{
    min-height: 0 !important;
}
</style>